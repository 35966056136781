import React from 'react';
import Layout from '../../../components/Layout';
import Merchants_Navbar from '../../../components/Merchants_Navbar';

function Index(props) {
    return (
        <div>
            <Layout location={'Merchants'} title={'Terminals'}>
            <Merchants_Navbar location={'terminals'}/>
            <section className="cw-bg-bluegradient text-center py-10 px-5">
                <div className="text-center w-100">
                    <h1 className="text-5xl text-white font-bold pb-2">Terminals</h1>
                </div>
            </section>
            <section className='py-20 bg-gray-100'>
                <div className="max-w-3xl mx-auto px-5 text-center">
                    <div className='mb-7 space-y-5'>
                        <h2 className="text-3xl cw-text-lightblue font-bold mb-3">Payment Processing Equipment: Finding the right fit for your business</h2>
                        <p className="font-serif text-xl leading-8 text-left">
                            Every industry has its own take on the saying 'pick the right tool for the job.' In the world of merchant services, it's pick 
                            the right solution for the business. At CWA, we help merchants get equipped with the right solution for every 
                            step in the credit card payment process — starting with placing the terminal.
                        </p>
                        <p className="font-serif text-xl leading-8 text-left">
                            Curious about what the right terminal can do for your business? Here's a quick glimpse at the terminals we currently offer.  
                            Have a different terminal?  No problem, we can easily reprogram your existing terminal and begin processing right away!
                        </p>
                        <p className="font-serif text-xl leading-8 text-left">
                            All new CWA Terminals are now EMV compliant!  Have any EMV related questions, contact Merchant Support today, we are glad 
                            to help.  CWA puts a priority on security and by offering these state-of-the-art solutions, we are continuing to push the 
                            envelope and maintain the utmost level of safety for our family of merchants.
                        </p>
                    </div>
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">Pax S80</h2>
                            <p className="py-4 font-serif">
                                PAX's S80 is an advanced counter top POS terminal which combines innovation, durability and high performance to assure 
                                faster, more reliable transactions.
                            </p>
                            <p className="py-4 font-serif">
                                The PCI PTS 3.x approved S80 offers advanced connectivity, equipped with various communication technologies such as Dial 
                                and Ethernet. An intuitive ATM-style interface and ergonomic keypad make operation easier for merchant and consumer alike.
                            </p>
                            <p className="py-4 font-serif">
                                The S80 comes with powerful ARM11 processor and optional built-in contactless, enabling support for multiple payment and 
                                value-added applications, making this counter top terminal one of the most reliable in the world. The S80 allows for tip 
                                adjustment even with EMV technology, great for restaurants!
                            </p>
                        </div>
                        <div className="m-auto">
                            <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650471051/cwa/assets/images/Merchants/Terminals/PAX-Technology-S80-Image_uukqoe.jpg"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650471242/cwa/assets/images/Merchants/Terminals/S300_7_znervh.jpg"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">PAX S300 Integrated PinPad</h2>
                            <p className="py-4 font-serif">
                                PAX's S300 is the latest integrated retail payment solution for multilane merchants who wish to offer high levels of 
                                transactional security combined with contactless, e-Signature, magnetic stripe and Chip & PIN. With state of the art 
                                levels of security design, including PCI PTS 3.x and SRED, the S300 protects and encrypts all transaction information. 
                                Featuring a large color touch screen and loudspeaker, the S300 comes with a 32- bit ARM11 processor and massive amounts 
                                of memory for exceptional multimedia performance.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">Verifone Vx805 (PinPad)</h2>
                            <p className="py-4 font-serif">
                                Verifone's VX 805 Contactless PIN pad provides precisely what you need, and nothing you don't. This incredibly convenient 
                                payment device allows merchants to process a range of transactions through multiple connectivity options, while offering 
                                the latest NFC technology for mobile payments. And, of course, it includes the latest security protections for total peace of mind.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650471516/cwa/assets/images/Merchants/Terminals/vx805_gitc0u.jpg"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650471629/cwa/assets/images/Merchants/Terminals/poynt-smart_ucazr8.jpg"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">Poynt Smart</h2>
                            <p className="py-4 font-serif">
                                Accept payment anywhere with security and confidence. Two screens. One for you and one for your customers to view their 
                                transactions. Completely mobile. Built-in printer and scanner. Single slot for mag stripe and chip cards. Real time sales 
                                reporting. NFC reader compatible. Poynt HQ Control Center.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">Poynt 5</h2>
                            <p className="py-4 font-serif">
                            Payments can happen outside of the checkout line. Offer your customers the most modern payment experience, without sacrificing 
                            security or features. Efficient. Secure. Convenient.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650471692/cwa/assets/images/Merchants/Terminals/poynt-5-e1565033727940_vsfkny.png"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="m-auto">
                            <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650471774/cwa/assets/images/Merchants/Terminals/Z11_soec3s.png"/>
                        </div>
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">Dejavoo Z11 Touch Comm Touch Screen</h2>
                            <p className="py-4 font-serif">
                                Z11 countertop payment terminals provide an easy to use EMV payment experience for every business owner. With easy access 
                                to frequently used options in the “Favorites” Menu, and a simple “Call Me” button to request a support representative callback, 
                                merchants love the ease of use and ISOs love the easy programming of Dejavoo. Apple Pay, Samsung Pay, Google Wallet, Visa payWave, 
                                MasterCard PayPass. Ideal for signature capture and upload to the DeNovo business portal.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="font-bold text-lg text-blue-900">Dejavoo Z9 Wireless Terminal – WiFi or 3G</h2>
                            <p className="py-4 font-serif">
                            Z9 Wireless Terminal allows you to use your own WiFi wireless network. Connects easily, terminal finds available networks. 
                            Multiple options keep you connected. EMV and NFC contactless built-in. Apple Pay, Samsung Pay, Visa payWave, MasterCard PayPass, 
                            Prepaid Data Plans for 3G Models – No Gateways, sends an IP Transaction over the GPRS Radio. 3G Models use multiple data networks for 
                            unbeatable coverage map. Ideal for signature capture and upload to the DeNovo business portal.
                            </p>
                        </div>
                        <div className="m-auto">
                            <img style={{height:'auto', width:'365px'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1650471861/cwa/assets/images/Merchants/Terminals/dejavoo-z9_f5ieyl.jpg"/>
                        </div>
                    </div>  
                </div>
            </section>
            {/* <section className="cw-bg-bluegradient">
                <div className="px-5 text-center lg:text-left flex lg:justify-between py-5 align-center max-w-5xl mx-auto space-y-3">
                    <h3 className="text-white text-xl font-serif max-w-xl leading-8">Know a business that accepts credit cards? Connect them with CWA and earn $100 for EVERY referral!</h3>
                    <button className="transition ease-in-out delay-150 border-2 border-white p-2 text-white hover:bg-white hover:cw-text-lightblue font-semibold text-lg">Go to Refer a Merchant</button>
                </div>
            </section> */}

            </Layout>            
        </div>
    );
}

export default Index;